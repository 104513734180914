<template>
  <div>
    <section>
      <article>
        <div class="container mt-5">
          <div class="packageHeader">
            <el-breadcrumb separator-class="el-icon-arrow-right">
              <el-breadcrumb-item :to="{ path: '/' }">Home</el-breadcrumb-item>
              <el-breadcrumb-item :to="{ path: '/safari-packages' }">All Tours</el-breadcrumb-item>
              <el-breadcrumb-item v-if="$route.query.whereTo">{{$route.query.whereTo}} Tours</el-breadcrumb-item>
              <el-breadcrumb-item v-else>African Safari Tours & Holidays</el-breadcrumb-item>
            </el-breadcrumb>
            <el-form
              :model="safariPackageForm"
              ref="safariPackageForm"
              class="demo-safariPackageForm2"
            >
              <div class="where_form">
                <div>
                  <el-form-item
                    label="Where to?"
                    style="width: 100%;"
                  >
                    <el-select
                      v-model="safariPackageForm.destination_country"
                      placeholder="Where To?"
                      style="width: 100%"
                      @change="changeToACountryTours"
                    >

                      <el-option
                        v-for="country in destinations"
                        :key="country.id"
                        :label="country.country_title"
                        :value="country.country_title"
                      >
                      </el-option>
                    </el-select>
                  </el-form-item>
                </div>
              </div>

            </el-form>
          </div>
        </div>
      </article>
    </section>

    <section>
      <article class="container">
        <hr style="width: 100%; background-color: #C1AA7F; height: 0.2px;" />
        <div>
          <section>
            <article>
              <div class="mt-4 mb-5">

                <section v-if="$route.query.whereTo">
                  <article>
                    <div class="">
                      <div>
                        <h1 style="font-size: 1.5em; font-weight: 600;">
                          {{ $route.query.whereTo }} Safari Tours & Holidays
                        </h1>
                        <p style="font-size: 0.9em; font-weight: 300;">
                          {{ country_overview }}
                        </p>
                      </div>
                      <div
                        v-if="isLoading"
                        class="px-0 mt-3 col-12"
                      >
                        <div class="products_skeletons">
                          <div
                            v-for="i in 8"
                            :key="i"
                            class="skeleton-block skeleton-effect-wave"
                          ></div>
                        </div>
                      </div>
                      <div v-else>

                        <article
                          v-if="safariPackagesByCountry.length == 0"
                          class="pt-5 pb-5"
                        >
                          <p>No Safari Packages added yet</p>
                        </article>
                        <article v-else>
                          <section>
                            <div
                              class="tour_package_grid"
                              data-aos="zoom-in-down"
                              data-aos-duration="3000"
                            >
                              <div
                                class="tour_package_item"
                                v-for="tour of safariPackagesByCountry"
                                :key="tour.id"
                              >
                                <div
                                  style="cursor: pointer;"
                                  @click="$router.push({ path: `/package-detail/${tour.title}/${tour.id}` })"
                                >
                                  <img :src="tour.image">
                                  <div>
                                    <a>
                                      <div class="text-white tour_title">
                                        <span>{{tour.title}}</span>
                                      </div>
                                    </a>
                                  </div>
                                </div>

                                <div class="package_brief_detail">
                                  <div class="package_description">
                                    <!-- <span class="package_amount">
                                      <strong>${{tour.amount}} pp (USD).</strong>
                                    </span><br> -->
                                    <span>
                                      <small
                                        class="desc_desktop"
                                        :inner-html.prop="tour.brief_description | truncate(180, '...')"
                                      >
                                        <!-- {{ tour.brief_description | truncate(180, '...')   }} -->
                                      </small>
                                      <small
                                        class="desc_mobile"
                                        :inner-html.prop="tour.brief_description | truncate(80, '...')"
                                      >
                                        <!-- {{ tour.brief_description | truncate(80, '...')   }} -->
                                      </small>
                                    </span><br />
                                    <span>
                                      <i class="el-icon-location-outline"></i>
                                      {{ tour.destination_country }}
                                    </span>
                                  </div>
                                  <div
                                    class="package_detail_link"
                                    @click="$router.push({ path: `/package-detail/${tour.title}/${tour.id}` })"
                                  >
                                    <span>Explore This Tour <i class="el-icon-d-arrow-right"></i></span>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </section>
                        </article>
                      </div>

                    </div>
                  </article>
                </section>

                <section v-else>
                  <article>
                    <div class="">
                      <div>
                        <h1 style="font-size: 1.5em; font-weight: 600;">African Safari Tours & Holidays</h1>
                        <p style="font-size: 0.9em; font-weight: 300;">
                          Does an African safari feature on your bucket list? It should! Africa has so much to offer,
                          from spectacular scenery, friendly people and cultural treasures to blissful beaches.
                          But above all else, it is the wildlife and safari lifestyle that will draw you back,
                          again and again. This well-known quote by Richard Mullin is so very true: ‘The only man I envy,
                          is the man who has not yet been to Africa – for he has so much to look forward to’.
                          Once you go on your first safari, Africa gets into your blood.
                          You develop a deep longing to return to this magical continent,
                          and to start planning your next African safari holiday.
                          Be warned, Africa is very addictive!
                        </p>
                      </div>
                      <div
                        v-if="isLoading"
                        class="px-0 mt-3 col-12"
                      >
                        <div class="products_skeletons">
                          <div
                            v-for="i in 8"
                            :key="i"
                            class="skeleton-block skeleton-effect-wave"
                          ></div>
                        </div>
                      </div>
                      <div v-else>
                        <article
                          v-if="safari_tours.length == 0"
                          class="pt-5 pb-5"
                        >
                          <p>No Safari Packages added yet</p>
                        </article>
                        <article v-else>
                          <section>

                            <div
                              class="tour_package_grid"
                              data-aos="zoom-in-down"
                              data-aos-duration="3000"
                            >
                              <div
                                class="tour_package_item"
                                v-for="tour of safari_tours"
                                :key="tour.id"
                              >
                                <div
                                  style="cursor: pointer;"
                                  @click="$router.push({ path: `/package-detail/${tour.title}/${tour.id}` })"
                                >
                                  <img :src="tour.image">
                                  <div>
                                    <a>
                                      <div class="text-white tour_title">
                                        <span>{{tour.title}}</span>
                                      </div>
                                    </a>
                                  </div>
                                </div>

                                <div class="package_brief_detail">
                                  <div class="package_description">
                                    <!-- <span class="package_amount">
                                      <strong>${{tour.amount}} pp (USD).</strong>
                                    </span><br> -->

                                    <span>
                                      <small
                                        class="desc_desktop"
                                        :inner-html.prop="tour.brief_description | truncate(180, '...')"
                                      >
                                        <!-- {{ tour.brief_description | truncate(180, '...')   }} -->
                                      </small>
                                      <small
                                        class="desc_mobile"
                                        :inner-html.prop="tour.brief_description | truncate(80, '...')"
                                      >
                                        <!-- {{ tour.brief_description | truncate(80, '...')   }} -->
                                      </small>
                                    </span><br />

                                    <span>
                                      <i class="el-icon-location-outline"></i>
                                      {{ tour.destination_country }}
                                    </span>
                                  </div>
                                  <div
                                    class="package_detail_link"
                                    @click="$router.push({ path: `/package-detail/${tour.title}/${tour.id}` })"
                                  >
                                    <span>Explore This Tour <i class="el-icon-d-arrow-right"></i></span>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </section>
                        </article>
                      </div>
                    </div>
                  </article>
                </section>

              </div>
            </article>
          </section>
        </div>
      </article>
    </section>

  </div>
</template>

<script>
export default {
  data() {
    return {
      isLoading: false,
      loading: false,
      loadingError: false,

      safari_tours: [],
      safariPackage: {},
      destinations: [],
      safariPackagesByCountry: [],
      country_overview: "",

      safariPackageForm: {
        destination_country: "",
        check_in_date: "",
        number_adult_guest: "",
        number_children_guest: "",
      },
    };
  },

  watch: {
    "$route.query.whereTo": {
      handler: function (whereTo) {
        if (whereTo != undefined) {
          this.getSafariPackagesByCountry();
        } else {
          this.getSafariPackages();
          this.safariPackageForm.destination_country = "";
        }
      },
      deep: true,
      immediate: true,
    },
  },

  mounted() {
    if (this.$route.query.whereTo) {
      this.safari_tours = [];
      this.getSafariPackagesByCountry();
    } else {
      this.getSafariPackages();
    }
    this.getDestinations();
  },

  methods: {
    handleChange(value) {
      console.log(value);
    },
    async getSafariPackages() {
      this.isLoading = true;
      this.isLoadingError = false;
      try {
        //TODO: MAKE THIS REQUEST DYNAMIC AND NOT STATIC
        let request = await this.$http.get(`api/safari/package/get_all`);
        if (request.data.success) {
          // this.lecturerCourseUnits = request.data.lecturer_course_units;
          this.safari_tours = request.data.safari_tours;
        } else throw "UNEXPECTED_RESPONSE_RECEIVED";
      } catch (error) {
        this.isLoadingError = true;
      } finally {
        this.isLoading = false;
      }
    },

    async getDestinations() {
      this.isLoading = true;
      this.isLoadingError = false;
      try {
        //TODO: MAKE THIS REQUEST DYNAMIC AND NOT STATIC
        let request = await this.$http.get(
          `api/safari/destination/country/get_all`
        );
        if (request.data.success) {
          this.destinations = request.data.countries;
        } else throw "UNEXPECTED_RESPONSE_RECEIVED";
      } catch (error) {
        this.isLoadingError = true;
        return;
      } finally {
        this.isLoading = false;
      }
    },

    changeToACountryTours() {
      this.$router.push({
        path: "/safari-packages",
        query: { whereTo: `${this.safariPackageForm.destination_country}` },
      });
    },

    async getSafariPackagesByCountry() {
      this.isLoading = true;
      this.isLoadingError = false;
      try {
        //TODO: MAKE THIS REQUEST DYNAMIC AND NOT STATIC
        let request = await this.$http.get(
          `api/safari/tours/where_to?v=${this.$route.query.whereTo}`
        );
        if (request.data.success) {
          // this.lecturerCourseUnits = request.data.lecturer_course_units;
          this.safariPackagesByCountry = request.data.safari_tours;
          this.country_overview = request.data.country_overview;
        } else throw "UNEXPECTED_RESPONSE_RECEIVED";
      } catch (error) {
        this.isLoadingError = true;
      } finally {
        this.isLoading = false;
      }
    },
  },
};
</script>


<style scoped>
.packageHeader {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
}
.tour_title {
  font-size: 1.2em;
  font-weight: 600;
  text-transform: capitalize;
}
.products_skeletons {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 20px;
}

.products_skeletons > div {
  height: 200px;
  border-radius: 5px;
}

.desc_desktop {
  display: block;
}
.desc_mobile {
  display: none;
}

.package_details_description {
  height: 100%;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  border: 1px solid red;
}

.package_details_description > div {
  padding: 20px;
}

.package_details_description .package_detail_link {
  height: 100%;
  border: 1px solid red;
  display: flex;
  align-items: flex-end;
}

.tour_package_grid {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 20px;
}
.tour_package_grid > div {
  background-color: #f5f7f8;
  box-shadow: rgba(0, 0, 0, 0.02) 0px 1px 3px 0px,
    rgba(27, 31, 35, 0.15) 0px 0px 0px 1px;
  border-radius: 5px;
}
.tour_package_grid > div:hover {
  -webkit-box-shadow: 10px 10px 16px -10px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 10px 10px 16px -10px rgba(0, 0, 0, 0.75);

  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}
.tour_package_grid > div > div:first-child {
  position: relative;
  width: 100%;
}
.tour_package_grid > div > div:first-child > div {
  position: absolute;
  top: 0px;
  bottom: 0px;
  width: 100%;
  background-image: linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.8));
  display: flex;
  align-items: flex-end;
}
.tour_package_grid > div > div:first-child > div a {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: flex-end;
  padding: 10px 20px;
  text-decoration: none;
}
.tour_package_grid > div > div:nth-child(2) {
  transition: all 2s ease;
  border-radius: 0px 0px 0px 0px;
}
.tour_package_grid .tour_package_item img {
  border-radius: 5px 5px 0px 0px;
  width: 100%;
  height: 200px;
  object-fit: cover;
  transition: all 2s ease;
}
.tour_package_item {
  display: flex;
  align-items: center;
  justify-content: space-between;

  flex-direction: column;
  height: 100%;
  /* position: relative;
  transition: 1s; */
}

.package_brief_detail {
  display: flex;
  align-items: center;
  justify-content: space-between;

  flex-direction: column;
  height: 100%;
}
.package_brief_detail > div {
  width: 100%;
}
.package_description {
  padding: 20px 20px 20px 20px;
}
.package_detail_link {
  padding: 20px 20px 20px 20px;
  border-top: 1px solid #eeeeee;
  background-color: #f0f9eb;
  cursor: pointer;
  border-radius: 5px;
  text-transform: uppercase;
  font-weight: 300;
}

/*##Device = Tablets, Ipads (portrait)##Screen = B/w 768px to 1024px*/
@media (min-width: 768px) and (max-width: 1024px) {
}

/*##Device = Tablets, Ipads (landscape)##Screen = B/w 768px to 1024px*/
@media (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
}

/*##Device = Low Resolution Tablets, Mobiles (Landscape) ##Screen = B/w 481px to 767px*/
@media (min-width: 481px) and (max-width: 767px) {
  .packageHeader {
    display: block;
  }
  .tour_title {
    font-size: 1em;
  }
  .tour_package_grid > div > div:first-child > div a {
    padding: 10px 10px;
  }
  .package_amount {
    font-size: 1em;
  }

  .desc_desktop {
    display: none;
  }
  .desc_mobile {
    display: block;
  }
  .products_skeletons {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 10px;
  }
  .tour_package_grid {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 10px;
  }
  .package_description {
    padding: 10px;
  }
  .package_detail_link {
    padding: 10px;
    font-size: 0.7em;
  }
}

/*##Device = Most of the Smartphones Mobiles (Portrait) ##Screen = B/w 320px to 479px*/
@media (min-width: 320px) and (max-width: 480px) {
  .packageHeader {
    display: block;
  }
  .tour_title {
    font-size: 1em;
  }
  .tour_package_grid > div > div:first-child > div a {
    padding: 10px 10px;
  }
  .package_amount {
    font-size: 1em;
  }

  .desc_desktop {
    display: none;
  }
  .desc_mobile {
    display: block;
  }
  .products_skeletons {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 10px;
  }
  .tour_package_grid {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 10px;
  }
  .package_description {
    padding: 10px;
  }
  .package_detail_link {
    padding: 10px;
    font-size: 0.7em;
  }
}
</style>


<style>
.where_form .el-form-item {
  margin-bottom: 0px;
}

.where_form .el-form--label-top .el-form-item__label {
  float: none;
  display: inline-block;
  text-align: left;
  padding: 0 0 0px;
}
.where_form label {
  display: inline-block;
  margin-bottom: 0px;
  font-size: 0.7em;
  /* color: white !important; */
}

.where_form .el-select .el-input__inner {
  cursor: pointer;
  padding-right: 35px;
  border: 2px solid #c1aa7f;
  background-color: #c1aa7f;
  color: white !important;
}
</style>